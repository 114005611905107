import React from 'react'
import {  Menu, theme, Typography } from 'antd'
import { Link } from 'react-router-dom'
import './Navbar.scss'
import { BankOutlined, DatabaseOutlined, FireOutlined, GlobalOutlined, HomeOutlined } from '@ant-design/icons'
export const isMobile = window.innerWidth < 500

const Navbar = ({ hidden = false }) => {
  if (hidden) return

  const {
    token: { colorBgContainer }
  } = theme.useToken()

  return (
    <Menu theme="dark" mode="horizontal">
      <Menu.Item key="drangedal" icon={<FireOutlined />}>
        <Link to="/cabin-outside">Drangedal</Link>
      </Menu.Item>
      <Menu.Item key="oslo" icon={<HomeOutlined />}>
        <Link to="/oslo-outside">Oslo</Link>
      </Menu.Item>
      <Menu.Item key="skien" icon={<GlobalOutlined />}>
        <Link to="/skien-loft">Skien</Link>
      </Menu.Item>
      <Menu.Item key="drivhus" icon={<HomeOutlined />}>
        <Link to="/drivhus">Drivhus</Link>
      </Menu.Item>
      <Menu.Item key="fridge" icon={<DatabaseOutlined />}>
        <Link to="/fridge">Fridge</Link>
      </Menu.Item>
    </Menu>
  )
}

export default Navbar
