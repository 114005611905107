// import { Chart as ChartJS, CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend, TimeScale } from 'chart.js'
import 'chartjs-adapter-date-fns'; // Required for time-based scales
import { Line } from 'react-chartjs-2';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  TimeScale,
  Tooltip,
  Legend,
  Title
} from 'chart.js';

import { Divider, Spin } from 'antd'
import React from 'react'
import Modal from 'antd/es/modal/Modal'
import { LoadingModal } from '../../styles'
import { GraphButtons } from '../GraphButtons/GraphButtons'
// Register necessary components
ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  TimeScale,
  Tooltip,
  Legend
);

ChartJS.register(CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend, TimeScale)

export const DrawGraph = ({ logentryDataset, isLoading = false, showDataFromTimePeriod, setShowDataFromTimePeriod }) => {
  const [showModal, setShowModal] = React.useState(false)

  if (isLoading || !logentryDataset)
    return (
      <LoadingModal>
        {/* <div style={{ width: '10rem', height: '10rem', backgroundColor: 'white' }}> */}
        <Spin tip="Loading...">
          {/* <Alert message="Alert message title" description="Further details about the context of this alert." type="info" /> */}
        </Spin>
        {/* </div> */}
      </LoadingModal>
    )

  const measurementsThisYear = logentryDataset?.thisYear.sort((a, b) => new Date(a.date) - new Date(b.date))
  const measurementsLastYear = logentryDataset?.lastYear.sort((a, b) => new Date(a.date) - new Date(b.date))
  const options = {
    // responsive: true,
    // maintainAspectRatio: false,
    // layout: {
    //   padding: {
    //     left: 5,
    //     right: 5
    //   }
    // },
    type: 'line',
    responsive: true,
    scales: {
      x: {
        autoSkip: true,
        parsing: false,
        type: 'time',
        time: {
          unit: 'day'
        }
        // display: true,
        // title: {
        //   display: true,
        //   text: 'Date'
        // },
        // ticks: {
        //   major: {
        //     enabled: true
        //   },
        //   color: '#FF0000'
        // }
      },
      y: {
        beginAtZero: true,
        autoSkip: true
        // display: true,
        // title: {
        //   display: true,
        //   text: 'value'
        // }
      }
    },
    tooltips: {
      callbacks: {
        title: (tooltipItem, data) => {
          return data.datasets[tooltipItem[0].datasetIndex].data[tooltipItem[0].index].x.format('DD MMMM, YYYY')
        },
        label: (tooltipItem, data) => {
          return tooltipItem.value
        }
      }
    },
    plugins: {
      legend: {
        position: 'top'
      },
      title: {
        display: true,
        text: logentryDataset?.description // graphData?.yAxis?.title?.text || 'Hei'
      }
    }
  }
  //   const optionsOld = {
  //     responsive: true,
  //     scales: {
  //       x: {
  //         parsing: false,
  //         type: 'time',
  //         time: {
  //           unit: 'day'
  //         },
  //         // display: true,
  //         // title: {
  //         //   display: true,
  //         //   text: 'Date'
  //         // },
  //         ticks: {
  //           major: {
  //             enabled: true
  //           }
  //           // color: '#FF0000'
  //         }
  //       },
  //       y: {
  //         // beginAtZero: true
  //         // display: true,
  //         title: {
  //           display: true,
  //           text: logentryDataset?.unit
  //         }
  //       }
  //     },
  //     plugins: {
  //       legend: {
  //         position: 'top'
  //       }
  //       // title: {
  //       //   display: true,
  //       //   text: healthMetric?.description
  //       // }
  //     }
  //   }

  const data = (compact = false) => {
    const tension = 1
    const retVal = {
      labels: measurementsThisYear?.map(({ createdAt }) => createdAt),
      datasets: [
        {
          label: 'Current year',
          data: measurementsThisYear?.map((item) => item.value),
          borderColor: 'blue',
          backgroundColor: 'blue',
          tension,
          pointStyle: 'circle',
          pointRadius: showDataFromTimePeriod?.days === 1 ? 2 : 0.5,
          pointHoverRadius: 10,
          borderWidth: compact ? 1.4 : 2
        }
      ]
    }
    if (measurementsLastYear?.length)
      retVal.datasets.push({
        label: 'Last year',
        data: measurementsLastYear?.map((item) => item.value),
        borderColor: 'lightgrey',
        backgroundColor: 'lightgrey',
        tension,
        pointStyle: 'circle',
        pointRadius: 0.1,
        pointHoverRadius: 5,
        borderWidth: compact ? 1 : 2
      })
    return retVal
  }

  return (
    <div style={{ width: '100%' }}>
      {showDataFromTimePeriod && (
        <GraphButtons showDataFromTimePeriod={showDataFromTimePeriod} setShowDataFromTimePeriod={setShowDataFromTimePeriod} />
      )}
      <Line className="graph-styles" options={options} data={data(true)} onClick={() => setShowModal((prev) => !prev)} />
      <Divider />
      <Modal
        //   title="Create appointment"
        open={showModal}
        id="graph-fullsize-modal"
        // bodyStyle={{ minHeight: '80vh' }}
        onCancel={() => setShowModal(false)}
        bodyStyle={{ padding: 0, borderRadius: '20px' }}
        // className="modalStyle"
        width="80%"
        footer={null}
        centered
        // destroyOnClose
      >
        {showModal && (
          <div>
            <Line options={options} data={data(true)} onClick={() => setShowModal((prev) => !prev)} />
          </div>
        )}
      </Modal>
    </div>
  )
}



export const DrawFridgeGraph2 = ({ fridgeTempDataset, fridgeStatusDataset, isLoading = false, showDataFromTimePeriod, setShowDataFromTimePeriod }) => {
  const [showModal, setShowModal] = React.useState(false)

  if (isLoading || !fridgeTempDataset)
    return (
      <LoadingModal>
        <Spin tip="Loading...">
        </Spin>
      </LoadingModal>
    )

  const fridgeTempDataset_ = fridgeTempDataset?.thisYear.sort((a, b) => new Date(a.date) - new Date(b.date))
  const fridgeStatusDataset_ = fridgeStatusDataset?.thisYear.sort((a, b) => new Date(a.date) - new Date(b.date))
  console.log(fridgeTempDataset_.length, fridgeStatusDataset_.length)
  console.log(fridgeTempDataset_.at(-1), fridgeStatusDataset_.at(-1))
  // const options = {
  //   type: 'line',
  //   responsive: true,
  //   scales: {
  //     x: {
  //       autoSkip: true,
  //       parsing: false,
  //       type: 'time',
  //       time: {
  //         unit: 'day'
  //       }
  //     },
  //     y: {
  //       beginAtZero: true,
  //       autoSkip: true,
  //       // min: 0,
  //       // max: 50 // Set the range for the temperature graph
  //     },
  //     y1: {
  //       beginAtZero: true,
  //       autoSkip: true,
  //       position: 'right',
  //       min: 0,
  //       max: 20 // Set the range for the fridge status graph
  //     }
  //   },
  //   tooltips: {
  //     callbacks: {
  //       title: (tooltipItem, data) => {
  //         return data.datasets[tooltipItem[0].datasetIndex].data[tooltipItem[0].index].x.format('DD MMMM, YYYY')
  //       },
  //       label: (tooltipItem, data) => {
  //         return tooltipItem.value
  //       }
  //     }
  //   },
  //   plugins: {
  //     legend: {
  //       position: 'top'
  //     },
  //     title: {
  //       display: true,
  //       text: fridgeTempDataset_?.description // graphData?.yAxis?.title?.text || 'Hei'
  //     }
  //   }
  // }

  const data = (compact = false) => {
    const tension = 1
    const retVal = {
      labels: fridgeTempDataset_?.map(({ createdAt }) => new Date(createdAt)),
      datasets: [
        {
          label: 'Temperature',
          data: fridgeTempDataset_?.map((item) => item.value),
          borderColor: 'blue',
          backgroundColor: 'blue',
          tension,
          pointStyle: 'circle',
          pointRadius: showDataFromTimePeriod?.days === 1 ? 2 : 0.5,
          pointHoverRadius: 10,
          borderWidth: compact ? 1.4 : 2,
          yAxisID: 'y'
        }
      ]
    }
    if (fridgeStatusDataset_?.length)
      retVal.datasets.push({
        label: 'Fridge ON/OFF',
        // data: fridgeStatusDataset_?.map((item) => item.value === "true" ? 20 : 5),
        data: fridgeStatusDataset_?.map((item) => {
          return { x: new Date(item.createdAt), y: item.value === "true" ? 20 : 5 }

        }),
        borderColor: 'orange',
        backgroundColor: 'orange',
        tension,
        pointStyle: 'circle',
        pointRadius: 0.1,
        pointHoverRadius: 5,
        borderWidth: compact ? 1 : 2,
        yAxisID: 'y1'
      })
    return retVal
  }
  const options = {
    // responsive: true,
    // maintainAspectRatio: false,
    // layout: {
    //   padding: {
    //     left: 5,
    //     right: 5
    //   }
    // },
    type: 'line',
    responsive: true,
    scales: {
      x: {
        autoSkip: true,
        parsing: false,
        type: 'time',
        time: {
          unit: 'day'
        }
        // display: true,
        // title: {
        //   display: true,
        //   text: 'Date'
        // },
        // ticks: {
        //   major: {
        //     enabled: true
        //   },
        //   color: '#FF0000'
        // }
      },
      y: {
        beginAtZero: true,
        autoSkip: true
        // display: true,
        // title: {
        //   display: true,
        //   text: 'value'
        // }
      }
    },
    tooltips: {
      callbacks: {
        title: (tooltipItem, data) => {
          return data.datasets[tooltipItem[0].datasetIndex].data[tooltipItem[0].index].x.format('DD MMMM, YYYY')
        },
        label: (tooltipItem, data) => {
          return tooltipItem.value
        }
      }
    },
    plugins: {
      legend: {
        position: 'top'
      },
      title: {
        display: true,
        text: fridgeTempDataset_?.description // graphData?.yAxis?.title?.text || 'Hei'
      }
    }
  }

  return (
    <div style={{ width: '100%' }}>
      {showDataFromTimePeriod && (
        <GraphButtons showDataFromTimePeriod={showDataFromTimePeriod} setShowDataFromTimePeriod={setShowDataFromTimePeriod} />
      )}
      <Line className="graph-styles" options={options} data={data(true)} onClick={() => setShowModal((prev) => !prev)} />
      <Divider />
      <Modal
        open={showModal}
        id="graph-fullsize-modal"
        // bodyStyle={{ minHeight: '80vh' }}
        onCancel={() => setShowModal(false)}
        bodyStyle={{ padding: 0, borderRadius: '20px' }}
        // className="modalStyle"
        width="80%"
        footer={null}
        centered
        // destroyOnClose
      >
        {showModal && (
          <div>
            <Line options={options} data={data(true)} onClick={() => setShowModal((prev) => !prev)} />
          </div>
        )}
      </Modal>
    </div>
  )
}

export const DrawFridgeGraph = ({ fridgeTempDataset, fridgeStatusDataset, isLoading = false, showDataFromTimePeriod, setShowDataFromTimePeriod }) => {

  // Generate sample data
  const now = new Date();
  if (isLoading || !fridgeTempDataset)
    return (
      <LoadingModal>
        <Spin tip="Loading...">
        </Spin>
      </LoadingModal>
    )

  const fridgeTempDataset_ = fridgeTempDataset?.thisYear.sort((a, b) => new Date(a.date) - new Date(b.date))
  const fridgeStatusDataset_ = fridgeStatusDataset?.thisYear.sort((a, b) => new Date(a.date) - new Date(b.date))


  // const minuteData = Array.from({ length: 60 }, (_, i) => ({
  //   x: new Date(now.getTime() - (59 - i) * 60 * 1000), // Every minute
  //   y: Math.random() * 100, // Random value
  // }));

  // Dataset for every minute (60 points)
  const minuteData = fridgeTempDataset_?.map((item) => {
    return {
      x: new Date(item.createdAt),
      y: item.value
    }
  })
  // data: fridgeTempDataset_?.map((item) => item.value),

  // Dataset for every hour (10 points)
  const hourData = fridgeStatusDataset_?.map((item) => {
    return {
      x: new Date(item.createdAt),
      y: item.value
    }
  })
  const compact = false
  const   tension = 1
  const data = {
    datasets: [
      {
        label: 'Temperature',
        data: minuteData,
        borderColor: 'blue',
          backgroundColor: 'blue',
          tension,
          pointStyle: 'circle',
          pointRadius: showDataFromTimePeriod?.days === 1 ? 2 : 0.5,
          pointHoverRadius: 10,
          borderWidth: compact ? 1.4 : 2,
          yAxisID: 'y'
      },
      {
        label: 'Fridge ON/OFF',
        data: hourData,
        borderColor: 'orange',
          backgroundColor: 'orange',
          tension,
          pointStyle: 'circle',
          pointRadius: showDataFromTimePeriod?.days === 1 ? 2 : 0.5,
          pointHoverRadius: 10,
          borderWidth: compact ? 1.4 : 2,
          yAxisID: 'y'
      },
    ],
  };

  // const options = {
  //   // responsive: true,
  //   // maintainAspectRatio: false,
  //   // layout: {
  //   //   padding: {
  //   //     left: 5,
  //   //     right: 5
  //   //   }
  //   // },
  //   type: 'line',
  //   responsive: true,
  //   scales: {
  //     x: {
  //       autoSkip: true,
  //       parsing: false,
  //       type: 'time',
  //       time: {
  //         unit: 'day'
  //       }
  //       // display: true,
  //       // title: {
  //       //   display: true,
  //       //   text: 'Date'
  //       // },
  //       // ticks: {
  //       //   major: {
  //       //     enabled: true
  //       //   },
  //       //   color: '#FF0000'
  //       // }
  //     },
  //     y: {
  //       beginAtZero: true,
  //       autoSkip: true
  //       // display: true,
  //       // title: {
  //       //   display: true,
  //       //   text: 'value'
  //       // }
  //     }
  //   },
  //   tooltips: {
  //     callbacks: {
  //       title: (tooltipItem, data) => {
  //         return data.datasets[tooltipItem[0].datasetIndex].data[tooltipItem[0].index].x.format('DD MMMM, YYYY')
  //       },
  //       label: (tooltipItem, data) => {
  //         return tooltipItem.value
  //       }
  //     }
  //   },
  //   plugins: {
  //     legend: {
  //       position: 'top'
  //     },
  //     title: {
  //       display: true,
  //       text: fridgeTempDataset_?.description // graphData?.yAxis?.title?.text || 'Hei'
  //     }
  //   }
  // }

  const options = {
    // responsive: true,
    // maintainAspectRatio: false,
    // layout: {
    //   padding: {
    //     left: 5,
    //     right: 5
    //   }
    // },
    type: 'line',
    responsive: true,
    scales: {
      x: {
        autoSkip: true,
        parsing: false,
        type: 'time',
        time: {
          unit: 'day'
        }
        // display: true,
        // title: {
        //   display: true,
        //   text: 'Date'
        // },
        // ticks: {
        //   major: {
        //     enabled: true
        //   },
        //   color: '#FF0000'
        // }
      },
      y: {
        beginAtZero: true,
        autoSkip: true
        // display: true,
        // title: {
        //   display: true,
        //   text: 'value'
        // }
      }
    },
    tooltips: {
      callbacks: {
        title: (tooltipItem, data) => {
          return data.datasets[tooltipItem[0].datasetIndex].data[tooltipItem[0].index].x.format('DD MMMM, YYYY')
        },
        label: (tooltipItem, data) => {
          return tooltipItem.value
        }
      }
    },
    plugins: {
      legend: {
        position: 'top'
      },
      title: {
        display: true,
        text: fridgeTempDataset_?.description // graphData?.yAxis?.title?.text || 'Hei'
      }
    }
  }
  //   const optionsOld = {
  //     responsive: true,
  //     scales: {
  //       x: {
  //         parsing: false,
  //         type: 'time',
  //         time: {
  //           unit: 'day'
  //         },
  //         // display: true,
  //         // title: {
  //         //   display: true,
  //         //   text: 'Date'
  //         // },
  //         ticks: {
  //           major: {
  //             enabled: true
  //           }
  //           // color: '#FF0000'
  //         }
  //       },
  //       y: {
  //         // beginAtZero: true
  //         // display: true,
  //         title: {
  //           display: true,
  //           text: logentryDataset?.unit
  //         }
  //       }
  //     },
  //     plugins: {
  //       legend: {
  //         position: 'top'
  //       }
  //       // title: {
  //       //   display: true,
  //       //   text: healthMetric?.description
  //       // }
  //     }
  //   }

  return (
    <div style={{ width: '100%' }}>
      {showDataFromTimePeriod && (
        <GraphButtons showDataFromTimePeriod={showDataFromTimePeriod} setShowDataFromTimePeriod={setShowDataFromTimePeriod} />
      )}
      <Line className="graph-styles" options={options} data={data} />
      <Divider />
      
    </div>
  )

  // return (
  //   <div>
  //     <h2>Multi-Resolution Chart</h2>
  //     <Line data={data} options={options} />
  //   </div>
  // );
};