import axios from 'axios'
import { useQuery } from 'react-query'
import { nodeApiGatewayURL } from '../App'

export const useDaily = (options = {}) => {
  const URLFull = `${nodeApiGatewayURL}daily/`
  return useQuery(
    ['useDaily'],

    () =>
      axios
        .get(URLFull, {
          crossDomain: true,
          method: 'GET',
          headers: {
            'Content-Type': 'application/json'
          },
          xsrfCookieName: 'csrftoken_testtest',
          xsrfHeaderName: 'X-CSRFToken'
        })
        .then((response) => response.data)
        .then((data) => {
          return data
        })
        .catch((error) => {
          console.error('LogEntries Error:', error)
        }),
    {
      enabled: true,
      ...options
    }
  )
}

export const useLogEntryDataset = (showDataFromTimePeriod, title, type, options = {}) => {
  const URLFull = `${nodeApiGatewayURL}logentries/entries_from_period`
  return useQuery(
    ['useLogEntryDataset', showDataFromTimePeriod, title, type],

    () =>
      axios
        .get(URLFull, {
          crossDomain: true,
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            type,
            title,
            period: JSON.stringify(showDataFromTimePeriod),
            offset: 0
          },
          xsrfCookieName: 'csrftoken_testtest',
          xsrfHeaderName: 'X-CSRFToken'
        })
        .then((response) => response.data)
        .then((data) => {
          return data
        })
        .catch((error) => {
          console.error('useLogEntryDataset Error:', error)
        }),
    {
      enabled: true,
      ...options
    }
  )
}
