import { useState } from 'react'
import { CenteredContent } from '../styles'
import { DrawFridgeGraph} from '../components/Graph/DrawGraph'
import Navbar from '../components/Navbar/Navbar'
import { GraphHeadline } from '../components/GraphHeadLine/GraphHeadline'
import { GraphStats } from '../components/GraphStats/GraphStats'
import { useLogEntryDataset } from '../utils/fetchHooks'
import { SHOW_LAST_WEEK } from '../components/GraphButtons/GraphButtons'

const Fridge = () => {
  const [showDataFromTimePeriod, setShowDataFromTimePeriod] = useState(SHOW_LAST_WEEK)
  const { data: logEntriesData, isLoading } = useLogEntryDataset(showDataFromTimePeriod, 'oslo:fridge', 'temperature', {
    staleTime: 180000,
    refetchInterval: 60000
  })
  const { data: logEntriesDataDigital,isLoading: isLoadingDigital } = useLogEntryDataset(showDataFromTimePeriod, 'oslo:fridge', 'digital', {
    staleTime: 180000,
    refetchInterval: 60000
  })
console.log(logEntriesDataDigital)
  return (
    <div>
      <Navbar />
      <CenteredContent>
        <GraphHeadline graphTitle={logEntriesData?.description} logEntries={logEntriesData} isLoading={isLoading} />
        <GraphStats logentryDataset={logEntriesData} />
        {/* <DrawGraph
          logentryDataset={ {
            ...logEntriesDataDigital,
            thisYear: logEntriesDataDigital?.thisYear?.map((entry) => ({
              ...entry,
              value: entry.value === 'true' ? 10 : 5
            }))
          }}
          isLoading={isLoadingDigital}
          showDataFromTimePeriod={showDataFromTimePeriod}
          setShowDataFromTimePeriod={setShowDataFromTimePeriod}
        /> */}
        <DrawFridgeGraph
          fridgeTempDataset={logEntriesData}
          fridgeStatusDataset={ {
            ...logEntriesDataDigital,
            thisYear: logEntriesDataDigital?.thisYear?.map((entry) => ({
              ...entry,
              value: entry.value === 'true' ? 10 : 0
            }))
          }}
          isLoading={isLoading || isLoadingDigital}
          showDataFromTimePeriod={showDataFromTimePeriod}
          setShowDataFromTimePeriod={setShowDataFromTimePeriod}
        />
      </CenteredContent>
      
    </div>
  )
}

export default Fridge
